//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Icon } from "@iconify/vue2";
export default {
  name: "Hero",
  components: {
    Icon,
  },
  props: ["blok"],
  data() {
    return {
      iconColor: "#ffffff",
      mute: true,
    };
  },
  computed: {
    backgroundImageStyle() {
      return {
        backgroundImage: `url(${this.blok.bg_image.filename})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      };
    },
  },
  methods: {
    changeIconColor(isHovered) {
      this.iconColor = isHovered ? "#000000" : "#ffffff";
    },
    toggleSound(){
        this.mute = !this.mute
    }
  },
};
